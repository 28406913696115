<template>
    <b-container v-if="survey" id="survey" class="p-4" fluid>
        <div
            v-if="!isTask"
            class="back"
            @click="goBackToSurveys"
        >
            <font-awesome-icon :icon="['fas','chevron-left']" />
            <span class="px-2">{{ $t("survey.infoText") }}</span>
        </div>
        <div class="survey-title">
            <div class="share-experience">{{ $t("survey.titleText") }}</div>
            <!-- <div class="share-subtext">{{ $t("surveyRoute.titleText") }}</div>-->
        </div>
        <b-row class="my-4">
            <b-col md="3">
                <div class="side-info">
                    <img
                        v-if="survey.imageUrl"
                        :src="survey.imageUrl"
                        alt=""
                        class="w-100"
                    >
                    <div class="instructions p-4">
                        <div class="survey-alias mb-3">{{ survey.alias }}</div>
                        {{ survey.help }}
                    </div>
                </div>
            </b-col>
            <survey-questions
                v-if="!isCompleted"
                :survey="survey"
                @update-survey="updateSurvey"
                @update-ratings="updateRatings"
            />
            <survey-carousel v-else :survey="survey" />
        </b-row>
        <b-row v-if="!isCompleted" class="mt-5">
            <b-col lg="3" />
            <b-col>
                <b-button
                    class="submit-button"
                    @click="submit"
                >{{ $t('survey.buttonText') }}</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="3" />
            <b-col>

                <small v-if="submitHasBeenPrevented" class="submit-message">Please answer all questions</small>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import surveyService from '@/api/surveys'
    import config from '@/mixins/config'
    import eventBus from '@/utils/event-bus'
    import SurveyQuestions from '@/components/SurveyQuestions'
    import SurveyCarousel from '@/components/SurveyCarousel'
    import missionService from '@/api/mission'

    export default {
        components: {
            SurveyQuestions,
            SurveyCarousel
        },
        mixins: [ config ],
        data() {
            return {
                survey: null,
                ratings: [],
                submitHasBeenPrevented: false
            }
        },
        computed: {
            isCompleted() {
                return this.survey.completed
            },
            mission() {
                return this.$store.getters.getMission(
                    parseInt(this.$route.params.missionId)
                )
            },
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            isTask() {
                return this.task && this.task.id
            }
        },
        created() {
            surveyService.getSurvey(this.$route.params.surveyId).then(resp => {
                this.survey = resp.data
                this.survey.surveyQuestions.sort(this.sortQuestions)
            })
        },
        methods: {
            goBackToSurveys() {
                this.$router.push('/surveys')
            },
            submit() {
                if (!(this.ratings.length === this.survey.surveyQuestions.length)) {
                    this.submitHasBeenPrevented = true
                    return
                }

                let surveyId = this.$route.params.surveyId
                surveyService.submitSurvey(surveyId, this.ratings).then(() => {
                    surveyService.getSurvey(surveyId).then((res) => {
                        this.survey = res.data
                    })
                })
                if (this.isTask) {
                    let points = this.task.points
                    let finished = true
                    missionService.updateTask(this.mission, this.task, points, finished, this.$router)
                } else {
                    eventBus.$emit('openSurveySubmitModal', {
                        surveyAlias: this.survey.alias
                    })
                }
            },
            sortQuestions(a, b) {
                return a.position - b.position
            },
            updateSurvey(survey) {
                this.survey = { ...survey }
            },
            updateRatings(ratings) {
                this.submitHasBeenPrevented = false
                const ratingsArray = Object.entries(ratings)
                this.ratings = ratingsArray.map(x => ({ questionId: x[0], rating: x[1] }))
            }
        }
    }
</script>

<style lang="scss" scoped>
.img-box img {
    width: 35px;
}

.img-box p {
    text-align: center;
}

.survey-logo {
    width: 25px;
}

.alias-style {
    color: var(--secondary-color);
    font-size: 27px;
    padding-top: 20px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
}

.cursor-helper {
    cursor: pointer;
}

.total-votes {
    color: var(--secondary-color);
    font-weight: 700;
}

.opacity-background {
    background-color: rgb(0 0 0 / 40%);
}

.carousel-inner {
    height: 400px;
}

.question-title {
    font-weight: 700;
    font-size: 16px;
}

.carousel-icons {
    width: 25px;
}

.smile-labels-icon {
    width: 100%;
    align-items: center;
    justify-content: start;
}

.smile-labels {
    font-size: 15px;
    text-align: left;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.survey-text {
    font-weight: 700;
    text-align: left;
}

.back {
    display: flex;
    align-items: center;
    color: var(--main-content-text);
    text-align: left;
    cursor: pointer;
}

.back span {
    font-size: 85%;
}

.back svg {
    color: var(--main-content-text);
}

.survey-title {
    text-align: left;
}

.survey-title .share-experience {
    font-weight: bold;
    font-size: 150%;
    color: var(--secondary-color);
}

.survey-title .share-subtext {
    font-size: 80%;
    color: var(--main-content-text);
}

.side-info {
    text-align: left;
}

.side-info .instructions {
    background-color: var(--white);
    font-size: 85%;
}

.side-info .instructions .survey-alias {
    font-weight: bold;
    font-size: 125%;
}

.question-container {
    background: rgb(0 0 0 / 30%);
    color: var(--white);
}

.submit-button {
    padding: 8px 25px;
    font-size: 100%;
    border-radius: 20px;
    border: 1px solid var(--white);
    background: rgb(0 0 0 / 30%);
    color: var(--white);
    outline: none;
}

.submit-message {
    color: var(--error);
}

.progress {
    background: var(--black);
}

.progress .color_0 { background: var(--survey-very-poor); }

.progress .color_1 { background: var(--survey-poor); }

.progress .color_2 { background: var(--survey-acceptable); }

.progress .color_3 { background: var(--survey-very-good); }

.progress .color_4 { background: var(--survey-excellent); }

</style>
