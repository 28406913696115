<template>
    <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        style="enable-background: new 0 0 100 100;"
        xml:space="preserve"
    >
        <path
            class="svg"
            :class="{'selected': selected}"
            d="M50.3,24.6c-14.1,0-25.5,11.4-25.5,25.5c0,14.1,11.4,25.5,25.5,25.5s25.5-11.4,25.5-25.5 C75.8,36,64.4,24.6,50.3,24.6z M59.2,39.6c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1c-2.4,0-4.2-1.8-4.2-4.1 C54.9,41.4,56.8,39.6,59.2,39.6z M41.1,39.6c2.4,0,4.2,1.8,4.2,4.1c0,2.3-1.8,4.1-4.2,4.1c-2.2,0-4.1-1.8-4.1-4.1 C36.9,41.4,38.8,39.6,41.1,39.6z M50,58.5c-6,0-11.2,3-14.5,7.6c1.9-6.3,7.6-10.8,14.5-10.8c6.8-0.1,12.5,4.5,14.4,10.7 C61.1,61.4,55.9,58.5,50,58.5z"
        />
    </svg>
</template>
<script>
    export default {
        props: {
            selected: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style lang="scss" scoped>
.svg {
    fill: var(--disabled-color);
}

.svg.selected {
    fill: var(--survey-poor);
}
</style>
