<template>
    <b-col>
        <b-container
            v-for="(question,index) in localSurvey.surveyQuestions"
            :key="question.id"
            class="question-container mb-1 p-2"
        >
            <b-row class="mt-2">
                <b-col lg="3" class="ml-2">
                    <div class="index-text mr-1">{{ index + 1 }}.</div>
                    <div class="survey-text">{{ question.questionText }}</div>
                </b-col>
                <b-col class="mx-2">
                    <b-row>
                        <div class="survey-icons-container">
                            <div
                                v-for="(smile, smileIndex) in question.smiles"
                                :key="`${smile.questionId}${smileIndex}`"
                                class="survey-icon-container"
                                @click="selectAnswer(question, smileIndex)"
                            >
                                <component
                                    :is="componentType(smile)"
                                    :selected="smile.selected"
                                    class="survey-icon"
                                />
                                {{ smile.label }}
                            </div>
                        </div>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </b-col>
</template>
<script>
    import SurveySvg1 from '@/components/svg/SurveySVG_1'
    import SurveySvg2 from '@/components/svg/SurveySVG_2'
    import SurveySvg3 from '@/components/svg/SurveySVG_3'
    import SurveySvg4 from '@/components/svg/SurveySVG_4'
    import SurveySvg5 from '@/components/svg/SurveySVG_5'
    export default {
        components: {
            SurveySvg1,
            SurveySvg2,
            SurveySvg3,
            SurveySvg4,
            SurveySvg5
        },
        props: {
            survey: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                ratings: {}
            }
        },
        computed: {
            localSurvey() {
                return this.survey
            }
        },
        created() {
            this.addSmilesOnQuestions()
        },
        methods: {
            addSmilesOnQuestions() {
                this.localSurvey.surveyQuestions.map((question) => {
                    question.smiles = [
                        {
                            questionId: question.id,
                            selected: false,
                            type: 'SurveySvg1',
                            label: this.$t('survey.ratings[0]')
                        },
                        {
                            questionId: question.id,
                            selected: false,
                            type: 'SurveySvg2',
                            label: this.$t('survey.ratings[1]')
                        },
                        {
                            questionId: question.id,
                            selected: false,
                            type: 'SurveySvg3',
                            label: this.$t('survey.ratings[2]')
                        },
                        {
                            questionId: question.id,
                            selected: false,
                            type: 'SurveySvg4',
                            label: this.$t('survey.ratings[3]')
                        },
                        {
                            questionId: question.id,
                            selected: false,
                            type: 'SurveySvg5',
                            label: this.$t('survey.ratings[4]')
                        }
                    ]
                })
            },
            componentType(smile) {
                switch (smile.type) {
                case 'SurveySvg1':
                    return SurveySvg1
                case 'SurveySvg2':
                    return SurveySvg2
                case 'SurveySvg3':
                    return SurveySvg3
                case 'SurveySvg4':
                    return SurveySvg4
                case 'SurveySvg5':
                    return SurveySvg5
                }
            },
            selectAnswer(question, smileIndex) {
                question.smiles.map((smile) => {
                    smile.selected = false
                })

                const smileToChange = question.smiles[smileIndex]
                smileToChange.selected = !smileToChange.selected

                this.$emit('update-survey', this.localSurvey)

                this.ratings[question.id] = smileIndex + 1
                if (Object.keys(this.ratings).length === this.survey.surveyQuestions.length) {
                    this.$emit('update-ratings', this.ratings)
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
.img-box img {
    width: 35px;
}

.img-box p {
    text-align: center;
}

.survey-logo {
    width: 25px;
}

.cursor-helper {
    cursor: pointer;
}

.smile-labels-icon {
    width: 100%;
    align-items: center;
    justify-content: start;
}

.survey-icons-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
}

.survey-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.survey-icon {
    width: 75px;
    height: 75px;
    cursor: pointer;
}

.smile-labels {
    font-size: 15px;
    text-align: left;
}

.survey-text {
    font-weight: 700;
    text-align: left;
}

.index-text {
    font-weight: 700;
    text-align: left;
}

.question-container {
    background: rgb(0 0 0 / 30%);
    color: var(--white);
}

</style>
