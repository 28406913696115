<template>
    <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        style="enable-background: new 0 0 100 100;"
        xml:space="preserve"
    >
        <path
            class="svg"
            :class="{'selected': selected}"
            d="M50.3,24.6c-14.1,0-25.5,11.4-25.5,25.5c0,14.1,11.4,25.5,25.5,25.5s25.5-11.4,25.5-25.5 C75.8,36,64.4,24.6,50.3,24.6z M59,40.2c2.3,0,4.2,1.8,4.2,4.1c0,2.3-1.9,4.2-4.2,4.2c-2.3,0-4.2-1.9-4.2-4.2 C54.8,42,56.7,40.2,59,40.2z M41,40.2c2.3,0,4.2,1.8,4.2,4.1c0,2.3-1.9,4.2-4.2,4.2c-2.2,0-4.1-1.9-4.1-4.2 C36.8,42,38.7,40.2,41,40.2z M50,67.9c-6.7,0-12.5-4.6-14.4-10.8c3.2,4.5,8.4,7.6,14.4,7.6c6,0,11.3-3,14.4-7.6 C62.6,63.4,56.9,67.9,50,67.9z"
        />
    </svg>

</template>
<script>
    export default {
        props: {
            selected: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style lang="scss" scoped>
.svg {
    fill: var(--disabled-color);
}

.svg.selected {
    fill: var(--survey-very-good);
}
</style>
