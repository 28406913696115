<template>
    <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 100 100"
        style="enable-background: new 0 0 100 100;"
        xml:space="preserve"
    >
        <path
            class="svg"
            :class="{'selected': selected}"
            d="M50.3,24.6c-14.1,0-25.5,11.4-25.5,25.5c0,14.1,11.4,25.5,25.5,25.5s25.5-11.4,25.5-25.5 C75.8,36,64.4,24.6,50.3,24.6z M59.1,38.2c2.3,0,4.2,1.8,4.2,4.1c0,2.3-1.9,4.2-4.2,4.2c-2.3,0-4.2-1.9-4.2-4.2 C54.9,40,56.8,38.2,59.1,38.2z M41.1,38.2c2.3,0,4.2,1.8,4.2,4.1c0,2.3-1.9,4.2-4.2,4.2c-2.2,0-4.1-1.9-4.1-4.2 C36.9,40,38.8,38.2,41.1,38.2z M35.9,62.7c1.8-6.3,7.5-10.8,14.3-10.8c6.9,0,12.6,4.5,14.4,10.8L35.9,62.7z"
        />
    </svg>
</template>
<script>
    export default {
        props: {
            selected: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style lang="scss" scoped>
.svg {
    fill: var(--disabled-color);
}

.svg.selected {
    fill: var(--survey-very-poor);
}
</style>
