<template>
    <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        style="enable-background: new 0 0 100 100;"
        xml:space="preserve"
    >
        <path
            class="svg"
            :class="{'selected': selected}"
            d="M50.3,24.6c-14.1,0-25.5,11.4-25.5,25.5s11.4,25.5,25.5,25.5s25.5-11.4,25.5-25.5S64.4,24.6,50.3,24.6z M38.4,39.6l4.8,1.5l5-1.2l0.4,2.2l-5.5,1.2l-5.4-1.7L38.4,39.6z M61.8,59.9H38.7v-3.2h23.2V59.9z M57.3,43.4l-5.5-1.7l0.7-2.1 l4.8,1.5l5-1.2l0.4,2.2L57.3,43.4z"
        />
    </svg>

</template>
<script>
    export default {
        props: {
            selected: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style lang="scss" scoped>
.svg {
    fill: var(--disabled-color);
}

.svg.selected {
    fill: var(--survey-acceptable);
}
</style>
